import React from "react"
import { FaFacebook, FaInstagram, FaRegEnvelope } from "react-icons/fa"

import Titre from "./titre"
import Navigation from "./navigation"

const Footer = () => {
  return (
    <footer className="footer">
      <div>
        <div>
          <Titre></Titre>
        </div>
        <div>
          <p>Navigation :</p>
          <Navigation></Navigation>
        </div>
        <div>
          <p>Nous suivre :</p>
          <p>
            <a className="bouton clair" href="">
              <FaRegEnvelope />
              &nbsp;Newsletter
            </a>
          </p>
          <p>
            <a className="bouton clair facebook" href="">
              <FaFacebook />
              &nbsp;Facebook
            </a>
          </p>
          <p>
            <a className="bouton clair instagram" href="">
              <FaInstagram />
              &nbsp;Instagram
            </a>
          </p>
        </div>
      </div>
      <p>
        © Tous droits réservés Ensemble Grand-Ouest {new Date().getFullYear()} –
        Réalisation Sébastien Gil
      </p>
    </footer>
  )
}

export default Footer
