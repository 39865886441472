import React from "react"

const Navigation = () => {
  const isBrowser = () => typeof window !== "undefined"
  var active = isBrowser() && window.location.pathname
  return (
    <nav>
      <ol>
        <li>
          <a
            style={active === "/" ? { borderBottom: "2px solid" } : null}
            href="/"
          >
            Accueil
          </a>
        </li>
        <li>
          <a
            style={
              active === "/presentation/" ? { borderBottom: "2px solid" } : null
            }
            href="/presentation"
          >
            Présentation
          </a>
        </li>
        <li>
          <a
            style={
              active === "/inscription/" ? { borderBottom: "2px solid" } : null
            }
            href="/inscription"
          >
            Inscription
          </a>
        </li>
        <li>
          <a
            style={
              active === "/contact/" ? { borderBottom: "2px solid" } : null
            }
            href="/contact"
          >
            Restons connectés
          </a>
        </li>
      </ol>
    </nav>
  )
}

export default Navigation
