import React from "react"

import logo from "../images/ensemble-go.png"

const Titre = (siteTitle) => {
  return (
    <a href="/">
      <h1 className="site-title">
        <img src={logo} alt={`Ensemble Grand-Ouest`} style={{ width: "150px" }} />
      </h1>
    </a>
  )
}

export default Titre
