import React from "react"
import validator from "validator"

const Newsletter = () => {
  const mailAddrRef = React.useRef()
  const [focused, setFocus] = React.useState(false)
  const [isErrored, setIsErrored] = React.useState(false)
  const [mailValue, setMailValue] = React.useState("")
  return (
    <section className="orange section" id="restons-connectes">
      <h2>Dernières infos</h2>
      <p>
        Abonnez-vous à notre newsletter pour recevoir les dernières infos
        concernant le collectif Ensemble Grand-Ouest.
      </p>
      <div id="mc_embed_signup">
        <form
          action="https://ensemble-go.us20.list-manage.com/subscribe/post?u=0cf585ca4ece4c2459d1a1806&amp;id=6aecefcef0"
          method="post"
          id="mc-embedded-subscribe-form"
          name="mc-embedded-subscribe-form"
          class="validate"
          target="_blank"
          novalidate
        >
          <div id="mc_embed_signup_scroll">
            <div
              className={
                "nl-input " +
                (focused ? "focused " : "") +
                (isErrored ? "error " : "")
              }
              onClick={() => {
                mailAddrRef.current.focus()
              }}
            >
              <input
                type="email"
                value={mailValue}
                name="EMAIL"
                class="email"
                id="mce-EMAIL mail-input"
                placeholder="Adresse email"
                required
                ref={mailAddrRef}
                onFocus={() => {
                  setFocus(true)
                }}
                onBlur={() => {
                  setFocus(false)
                }}
                onChange={e => {
                  setMailValue(e.target.value)
                  setIsErrored(false)
                }}
                onKeyPress={e => {
                  if (e.key === "Enter") {
                    if (!validator.isEmail(e.target.value)) {
                      setIsErrored(true)
                      return
                    }
                  }
                  setIsErrored(false)
                }}
              />
            </div>
            {/* <!-- real people should not fill this in and expect good things - do not remove this or risk form bot signups--> */}
            <div
              style={{ position: "absolute", left: "-5000px" }}
              aria-hidden="true"
            >
              <input
                type="text"
                name="b_0cf585ca4ece4c2459d1a1806_6aecefcef0"
                tabindex="-1"
                value=""
              />
            </div>
            <input
              className="button bouton bleu"
              type="submit"
              value="Inscription"
              name="subscribe"
              id="mc-embedded-subscribe"
            />
          </div>
        </form>
      </div>
    </section>
  )
}

export default Newsletter
